/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-raleway');
require('typeface-roboto');

exports.onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
